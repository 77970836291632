import { Controller } from "@hotwired/stimulus"
import { track } from "./analytics"

export default class extends Controller {
  static targets = ["item", "submit", "autofocus"]

  toggle() {
    if (this.isDropdownOpened) {
      this.filterBackdrop.classList.add(this.classes)
    } else {
      this.filterBackdrop.classList.remove(this.classes)
    }
    this.itemTarget.classList.toggle(this.classes)

    if (this.isDropdownOpened) {
      if (this.hasAutofocusTarget) {
        this.autofocusTarget.focus()
      }

      if (this.hasTracking) {
        track("Opened Filters", { filter_group: this.trackingName })
      }
    }
  }

  hide(event) {
    if (this.element.contains(event.target) || !this.isDropdownOpened) { return }

    let closeBackdrop = true
    // Check if the click is in a dropdown filter
    document.querySelectorAll('.search-dropdown').forEach(element => {
      if (element.contains(event.target)) {
        closeBackdrop = false
        return
      }
    })

    if (closeBackdrop) {
      this.filterBackdrop.classList.add(this.classes)

      if (event.target !== this.moreFiltersButton && this.hasSubmitTarget) {
        this.submitTarget.click()
      }
    }

    this.itemTarget.classList.add(this.classes)
  }

  toggleAndOpenRelatedFilter(event) {
    if (!this.relatedFilter || !this.isDropdownOpened) { return }

    this.toggle()
    this.filterBackdrop.classList.remove(this.classes)
    this.relatedFilter.querySelector("[data-nav-target='item']").classList.toggle("d-none")
    event.stopPropagation()
    track("Opened Filters", { filter_group: "packs" })

  }

  handleKeyPressed(e) {
    if (this.isDropdownOpened && e.keyCode === 13) {
      this.submitTarget.click()
    }
  }

  // private

  get isDropdownOpened() {
    return !this.itemTarget.classList.contains(this.classes)
  }

  get hasTracking() {
    return this.data.has("tracking")
  }

  get trackingName() {
    return this.data.get("tracking")
  }

  get classes() {
    return this.data.get("classes")
  }

  get filterBackdrop() {
    return document.getElementById('filters-backdrop')
  }

  get moreFiltersButton() {
    return document.getElementById('js-more-filters-button')
  }

  get relatedFilter() {
    return document.getElementById(this.data.get("relatedFilter"))
  }
}
