import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  disableSubmitOnEnter(e) {
    let keyCode = e.keyCode || e.which
    if (keyCode === 13) {
      e.preventDefault()
    }
  }
}
