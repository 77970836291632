import { Controller } from "@hotwired/stimulus"
import { track } from "./analytics"

export default class extends Controller {
  static targets = ["formSubmit"]

  connect() {
    // Listen to click on venue packs to preselect in the miniquote
    document.querySelectorAll(".js-packs-table").forEach(element => {
      element.addEventListener("click", () => this.handlePackClick(element))
    })
  }

  handlePackClick(element) {
    // Select category tabs and pack value from miniquote
    const pack = element.dataset["pack"]
    const category = element.dataset["category"]
    const categoryTab = document.querySelector(`div[href="#${category}-form"]`)

    if (categoryTab !== null) {
      categoryTab.click()
      document.getElementById(`${category}_form_${pack}`).click()

      track("Clicked on Page", { name: "Pack Price", target_id: pack })
    }
  }

  showSelection(e) {
    e.stopPropagation()
    $(".selection-button-container button").click();
  }

  submit() {
    this.formSubmitTarget.dataset["loader"] = true

    setTimeout(() => {
      this.formSubmitTarget.dataset["loader"] = false
    }, 1000)
  }
}
