import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    const options = {
      theme: "bootstrap",
      minimumResultsForSearch: Infinity,
      dropdownCssClass: "select2-dropdown--full",
      placeholder: this.data.get("placeholder"),
    }

    $(this.element).select2(options)
  }
}

