import {Controller} from "@hotwired/stimulus"
import {track} from "./analytics"

const MEDIUM_BREAKPOINT = 992

export default class extends Controller {
  connect() {
    $('[data-toggle="tooltip"]').tooltip()
  }

  togglePacksFilter(e) {
    e.preventDefault()
    e.stopPropagation()
    if (window.innerWidth < MEDIUM_BREAKPOINT) {
      this.mobileSearchBarElement.click()
    } else {
      document.getElementById("packs-filter-toggle").click()
      document.querySelector('.listing-body').scrollTo(0, 0)
    }
    track("Clicked on Page", { name: "Display packs" })
  }

  // Getters

  get mobileSearchBarElement() {
    return document.getElementById("mobile-filters-button").click()
  }
}
