import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkbox", "form"]

  toggle(e) {
    this.checkboxTarget.value = e.target.checked
    $(this.formTarget).trigger("submit.rails");
  }
}
