import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["packSelect", "venueCapacity", "guestsInput", "dropdownToggle", "formSubmit"]

  connect() {
    this.initPackSelect()
    this.initTooltips()
  }

  disconnect() {
    this.disposeTooltips()
  }

  initPackSelect() {
    this.showCapacitiesHint(this.data.get('selectedPack'))
  }

  initTooltips() {
    $('[data-toggle="tooltip"]', this.element).tooltip()
  }

  disposeTooltips() {
    $('[data-toggle="tooltip"]', this.element).tooltip("dispose")
  }

  handlePackSelection(e) {
    this.dropdownToggleTarget.innerText = e.target.dataset["labelText"]
    const pack = e.target.value
    this.data.set('selectedPack', pack)
    this.showCapacitiesHint(pack)
  }

  showCapacitiesHint(pack) {
    const packCapacity = this.venueCapacities[pack]
    if (packCapacity !== undefined) {
      this.venueCapacityTarget.innerText = packCapacity
      this.guestsInputTarget.classList.add("mb-0")
    } else {
      this.venueCapacityTarget.innerText = ""
      this.guestsInputTarget.classList.remove("mb-0")
    }
  }

  submit() {
    this.formSubmitTarget.dataset["loader"] = true
  }

  get venueCapacities() {
    return JSON.parse(this.data.get("venueCapacities")) || {}
  }
}

