import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static outlets = ["snapquote"]

  updateSnapquotePackageInput(event) {
    const itemType = event.params.itemType
    this.snapquoteOutlet.packageInputTarget.value = itemType
  }
}
