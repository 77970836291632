import { Controller } from "@hotwired/stimulus"
import qs from "qs"
import { track } from "./analytics"

const MEDIUM_BREAKPOINT = 992

export default class extends Controller {
  static targets = [ "packPrice", "totalPrice", "missingGuests" ]

  connect() {
    if (this.hasPackPriceTarget && this.guests > 0) {
      this.setTotalPrice()
    }
  }

  toggleGuestsFilter(e) {
    e.preventDefault()
    e.stopPropagation()
    if (window.innerWidth < MEDIUM_BREAKPOINT) {
      this.mobileSearchBarElement.click()
    } else {
      document.getElementById("guests-filter-toggle").click()
      document.querySelector('.listing-body').scrollTo(0, 0)
    }
    track("Clicked on Page", { name: "Display total price"  })
  }

  // Getters

  get packPrice() {
    return parseInt(this.packPriceTarget.dataset.price)
  }

  get guests() {
    // Pick guests from path to avoid cache issues
    const guests = qs.parse(window.location.search).guests
    if (guests !== undefined) {
      return guests
    } else {
      return 0
    }
  }

  get totalPrice() {
    return this.totalPriceTarget
  }

  get mobileSearchBarElement() {
    return document.getElementById("mobile-filters-button").click()
  }

  // Private

  setTotalPrice() {
    this.totalPrice.innerText = (this.packPrice * this.guests).toLocaleString("fr-FR", { style: "currency", currency: "EUR", maximumFractionDigits: 0 })
    this.totalPrice.parentNode.classList.remove("d-none")

    this.missingGuestsTarget.classList.add("d-none")
  }
}
